import Logotype from "components/Logotype";
import Modal from "components/modal";
import { contact_email } from "utils/constants";

export default function ModalPrivacyPolicy({ show, onHide }) {
	return (
		<Modal size="full" show={show} onHide={onHide} className="p-16" dialogClassName="z-40 w-full">
			<style jsx>
				{`
					p, section {
						margin-bottom: 16px;
					}

					h4 {
						font-size: 18px;
						text-transform: uppercase;
					}

					h5, h4 {
						font-weight: bold;
					}
				`}
			</style>
			<div className="container mx-auto">
				<p className="text-title3 ipad:text-title1 font-bold relative">
					Privacy Policy
					<a className="absolute right-0" onClick={onHide}>
						<span>
							<i className="ri-close-line" />
						</span>
					</a>
				</p>
				<p>
					<Logotype /> (&apos;we&apos; or &apos;us&apos; or &apos;our&apos;) respects the privacy of our users (&apos;user&apos; or &apos;you&apos;). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our mobile application (the &apos;App&apos;) or our website onboardcrewapp.com (the &apos;Site&apos;). Please read this Privacy Policy carefully. <strong>IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE APP OR SITE</strong>.
				</p>
				<p>
					We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will alert you about any changes by updating the &apos;Last updated&apos; date of this Privacy Policy. You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the App or Site after the date such revised Privacy Policy is posted.
				</p>

				<section>
					<h4>COLLECTION OF YOUR INFORMATION</h4>
					<p>We may collect information about you in a variety of ways. The information we may collect via the App or Site depends on the content, features and materials you use, and includes:</p>

					<section>
						<h5>Personal Data</h5>
						<p>We collect and store all personal information related to your App or Site profile, which you voluntarily give us either upon sign-up or through continued use of the App or Site:</p>
						<ul className="list-disc list-inside">
							<li>Name</li>
							<li>Email address</li>
							<li>Personal identifiers</li>
							<li>Address</li>
							<li>Phone number</li>
							<li>Race and ethnicity</li>
							<li>Political or religious beliefs</li>
							<li>Sexual orientation or gender identity</li>
							<li>Other personal info</li>
							<li>Approximate Location</li>
							<li>Photos Uploaded</li>
							<li>Files or Documents Uploaded</li>
						</ul>
					</section>

					<section>
						<h5>Derivative Data and Uploaded Media</h5>
						<p>Information our servers automatically collect when you access the App or Site, such as your native actions that are integral to the App or Site, actions taken when creating entries, editing entries and uploading media to the App or Site. As such, we may also request access to your device’s photo roll, camera, or files in order for us to upload your media or documents to the App or Site. Any media or documents uploaded in this manner will be collected and stored on our servers.</p>
					</section>

					<section>
						<h5>Geo-Location Information</h5>
						<p>We may request access or permission to and track location-based information from your mobile device, either continuously or while you are using the App or Site.</p>
					</section>

					<section>
						<h5>Mobile Device Data</h5>
						<p>Device information such as your mobile device ID number, model, and manufacturer, version of your operating system, phone number, country, location, and any other data you choose to provide.</p>
					</section>

					<section>
						<h5>Push Notifications</h5>
						<p>We may request to send you push notifications regarding your account or the App or Site.</p>
					</section>
				</section>

				<section>
					<h4>USE OF YOUR INFORMATION</h4>
					<p>Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the App or Site to:</p>
					<ul className="list-disc list-inside">
						<li>Generate a personal profile about you to provide functionality</li>
						<li>Monitor and analyze usage and trends to improve your experience with the App or Site</li>
						<li>Notify you of updates to the App or Site</li>
						<li>Request feedback and contact you about your use of the App or Site</li>
						<li>To improve our product based on your feedback</li>
						<li>We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the contact information you have provided</li>
					</ul>
				</section>

				<section>
					<h4>DISCLOSURE OF YOUR INFORMATION</h4>
					<p>We may share information we have collected about you in certain situations. Your information may be disclosed as follows:</p>
					<p>We will not sell, distribute, or lease your personal information to unrelated third parties unless we have your permission or are required by law to do so. We may need to send your personal information to our third-party vendors, who are under contractual obligations to keep this information confidential.</p>
					<p>We may also disclose your personal information:</p>
					<ul className="list-disc list-inside">
						<li>To comply with any court order, law or legal process, including to respond to any government or regulatory request.</li>
						<li>To enforce or apply our Terms of Use and other agreements, including for billing and collection purposes.</li>
						<li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of {<Logotype />}, our customers, or others. This may include exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li>
					</ul>
				</section>

				<section>
					<h4>SECURITY OF YOUR INFORMATION</h4>
					<p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse. Any information disclosed online is vulnerable to interception and misuse by unauthorized parties.</p>
				</section>

				<section>
					<h4>CHILDREN UNDER THE AGE OF 13</h4>
					<p>
						Our service is not intended for children under 13 years of age. No one under the age of 13 may provide any personal information to, or on, the App or Site. We do not knowingly collect personal information from children under 13. If you are under 13, do not use or provide any information on the App or Site, or on or through any of its features/functionality, make any purchases through the App or Site, or provide any information about yourself to us, including your name, address, telephone number, email address or any screen name or username you may use.
						If we learn that we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 13, please contact us at <a href={`mailto:${contact_email}`}>{contact_email}</a>.
					</p>
				</section>

				<section>
					<h4>OPTIONS REGARDING YOUR INFORMATION</h4>
					<section>
						<h5>Account Information</h5>
						<p>
							You can review and change your personal information by logging into your profile through the App or Site and visiting your account profile page or settings page.
							You may also send us an email at <a href={`mailto:${contact_email}`}>{contact_email}</a> to request access to, correct or delete  any personal information that you have provided to us. We may not be able to delete your personal information except by also deleting your user account. We also may not accommodate a request to change information if we believe the change would violate any law or legal requirement, or cause the information to be incorrect.
							Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.
						</p>
						<p>When we receive a termination or deletion request, all data that is deemed non-critical to the cases outlined above, will be retained at most 30 days in which period we will ensure complete deletion of such data from all databases and backups.</p>
					</section>

					<section>
						<h5>Emails and Communications</h5>
						<p>
							If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by:
						</p>
						<ul className="list-disc list-inside">
							<li>Contacting us using the contact information provided below.</li>
							<li>Clicking the opt-out button at the bottom of any promotional email you receive.</li>
						</ul>
					</section>
				</section>

				<section>
					<h4>NOTICE TO USERS FROM THE EUROPEAN ECONOMIC AREA</h4>
					<p>
						If you are visiting and using the Services from the European Economic Area (&apos;EEA&apos;), please be aware that you are sending information (including personal data) to where our partner platform’s servers are located. Our partners and/or staff operating outside the EEA may process this information. The information may then be transferred to other countries outside of your country of residence, depending on the type of information and how we store it. These countries may not necessarily have data protection laws as comprehensive or protective as those in your country of residence; however, our collection, storage and use of your personal data will at all times continue to be governed by this Privacy Policy.
						BY SUPPLYING YOUR PERSONAL INFORMATION TO US YOU EXPRESSLY AGREE TO THE TRANSFER OF YOUR PERSONAL INFORMATION OUT OF THE EEA, AND TO THE PROCESSING OF YOUR INFORMATION IN OTHER COUNTRIES OUTSIDE OF THE EEA, SUBJECT TO THIS PRIVACY POLICY.
					</p>
				</section>

				<section>
					<h4>YOUR CONSENT</h4>
					<p>By using the App or Site, you consent to our privacy policy.</p>
				</section>

				<section>
					<h4>CONTACT US</h4>
					<p>If you have questions or comments about this Privacy Policy, please contact us at <a href={`mailto:${contact_email}`}>{contact_email}</a></p>
				</section>
			</div>
		</Modal>
	);
}
