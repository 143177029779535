import { Dialog, Transition } from '@headlessui/react';
import { Fragment, ReactChild, ReactChildren, ReactElement, useRef } from 'react';

export type ModalPropsType = {
  onHide?: () => void,
  show: boolean,
  children: ReactChild | ReactChildren | Array<ReactElement>,
  size?: 'large' | 'full' | 'small',
  className?: string,
  dialogClassName?: string
}
export default function Modal({ onHide = () => {}, show, children, size, className = '', dialogClassName = '' }: ModalPropsType) {
  let contentRef = useRef(null);
  let maxWidth = 'max-w-md';

  switch (size) {
    case 'large':
      maxWidth = 'max-w-screen-ipad';
      break;
    case 'full':
      maxWidth = 'max-w-full'
      break;
    default:
      break;
  }

  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog
          as="div"
          className={`fixed inset-0 overflow-y-auto ${dialogClassName}`}
          onClose={onHide}
          initialFocus={contentRef}
        >
          <div ref={contentRef} className="min-h-screen px-4 text-center grid">
            
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-80"
              leave="ease-in duration-200"
              leaveFrom="opacity-80"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 w-full h-full opacity-90 bg-ink" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={`
                  inline-block
                  w-full
                  text-left
                  align-middle
                  transition-all
                  bg-white
                  shadow-xl
                  rounded-md
                  self-center
                  justify-self-center
                  transform 
                  ${maxWidth}
                  ${className}
                `}
              >
                {children}
              </div>
            </Transition.Child>
            
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
