import clsx from 'clsx';
import { ButtonHTMLAttributes, ReactElement } from 'react';

export type SizeType = 'xsmall' | 'small' | 'large' | 'medium';

type ButtonType = ButtonHTMLAttributes<HTMLButtonElement> & {
  text: string | ReactElement;
  icon?: string;
  leftIcon?: string;
  rightIcon?: string;
  size?: SizeType;
  secondary?: boolean;
  outline?: boolean;
  link?: boolean;
  disabled?: boolean;
  onClick?: Function;
  block?: boolean;
  className?: string;
};

const Button = ({
  text,
  icon,
  leftIcon,
  rightIcon,
  size,
  secondary = false,
  outline = false,
  link = false,
  disabled,
  onClick,
  block = false,
  className = '',
  ...others
}: ButtonType) => {
  let bg =
    'bg-primary hover:bg-primary-light disabled:bg-grey-dark focus:bg-primary-dark';
  let color = 'text-white';
  let textSize = 'large3';
  let content = text;
  let style = {};
  let padding = 'px-28';
  let sizes = 'h-40';
  let leftContent = null;
  let rightContent = null;

  if (outline) {
    color =
      'text-primary border-primary hover:text-white disabled:text-grey-dark disabled:border-grey-dark';
    bg = 'bg-white hover:bg-primary-light disabled:bg-white';
  }

  if (link) {
    color =
      'text-primary hover:text-grey disabled:text-grey-dark disabled:border-grey-dark';
    bg = '';
  }

  if (icon) {
    content = <i className={icon} />;
    style = {
      fontSize: '20px',
      lineHeight: '20px',
      verticalAlign: 'bottom',
    };
  }

  if (leftIcon || rightIcon) {
    let iconStyle = {
      verticalAlign: 'bottom',
    };
    if (leftIcon)
      leftContent = <i className={`${leftIcon} mr-12`} style={iconStyle} />;
    if (rightIcon)
      rightContent = <i className={`${rightIcon} ml-12`} style={iconStyle} />;
  }

  switch (size) {
    case 'xsmall':
      padding = 'px-16';
      if (icon) padding = 'p-0';
      textSize = 'small1';
      sizes = `${icon ? 'w-32' : ''} h-32`;
      break;
    case 'small':
      if (icon) padding = 'p-0';
      textSize = 'regular1';
      sizes = `${icon ? 'w-32' : ''} h-32`;
      break;
    case 'large':
      if (icon) padding = 'p-0';
      sizes = `${icon ? 'w-48' : ''} h-48`;
      break;
    case 'medium':
    default:
      if (icon) {
        sizes = 'h-40 w-40';
        padding = 'p-0';
      }
      break;
  }

  if (secondary) {
    bg =
      'bg-secondary-lightest hover:bg-secondary-dark disabled:bg-secondary-lighter focus:bg-secondary focus:text-white';
    color = 'text-secondary-dark hover:text-white disabled:text-white';

    if (outline) {
      bg = 'bg-white hover:bg-secondary-dark disabled:border-secondary-lighter';
      color = 'text-secondary hover:text-white disabled:text-secondary-lighter';
    }
  }

  const border = outline && !link ? `border` : '';

  function clickHandler(event) {
    if (onClick && !disabled) onClick(event);
  }

  if (block)
    sizes = icon
      ? sizes.replace(/^(w-)(32|48|40)/, 'w-full')
      : (sizes += ' w-full');

  const buttonClass = typeof className === 'string' ? className : '';

  return (
    <button
      className={clsx(
        !buttonClass.includes('rounded') && 'rounded-full',
        !buttonClass.includes('bg-') && bg,
        !buttonClass.includes('text-') && color,
        icon ? '' : padding,
        `text-${textSize}`,
        border,
        sizes,
        padding,
        buttonClass
      )}
      style={style}
      disabled={disabled}
      onClick={clickHandler}
      {...others}
    >
      {leftContent}
      {content}
      {rightContent}
    </button>
  );
};

export default Button;
