import NavbarLanding from "components/NavbarLanding";
import ScrollToTop from "components/ScrollToTop";
import Footer from "../Footer";
import MainHead from "../main/MainHead";

const LandingPageLayout: React.FC<{
  children: React.ReactNode;
  title: string;
  hasLoginToken?: boolean;
}> = ({ children, title, hasLoginToken }) => {

  return (
    <>
      <MainHead title={title}>
        <style jsx global>
          {`
            @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap');

            body .landing, .landing *, .landing p {
              font-family: 'Source Sans Pro', sans-serif;
            }
          `}
        </style>
      </MainHead>

      <div className="w-full min-h-screen landing">
        <NavbarLanding hasLoginToken={hasLoginToken} />
        {children}
        <Footer />
        <ScrollToTop />
      </div>
    </>
  );
};

export default LandingPageLayout;
