import Head from 'next/head';

export default function MainHead({ title, children }: {
  title: string;
  children?: React.ReactNode;
}) {
  return (
    <Head>
      <title>{title ? `${title} | ` : ''}OnBoard Crew App</title>
      {children}
    </Head>
  )
}
